// site logo

export default function WebbIconX() {

  return (
  <>
    <div className="" >
      <i className='bx bx-x text-color-tint text-icon-md'></i>    
    </div>

  </>
  )
}